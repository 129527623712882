<template>
  <div class="contain" :class="{long: comStatus == '1' && deptType == 'class' }">
    <div class="form-header-btn">
      <el-page-header
        @back="goBack"
        :content="'发病/患病率/查看'"
      >
        <template slot="title">
          <i class="el-icon-arrow-left"></i>返回
        </template>
      </el-page-header>
      <div v-if="comStatus == '1' && deptType == 'class'">
        <el-button type="primary" class="sheet-btn" @click="handleAddFill">
          新增患病
        </el-button>
      </div>
    </div>
    <!-- 表单 -->
    <el-form :model="form" onsubmit="return false" :inline="true" class="search-form">
      <el-form-item>
        <el-select
          v-if="comStatus == '3' && (deptType == 'park' || deptType == 'org')"
          v-model="form.parkId"
          clearable
          placeholder="选择园校"
          class="select-condition"
          :disabled="disabledPark"
          @change="handlePark"
        >
          <el-option
            v-for="item in moduleParkOptions"
            :key="item.label"
            :label="item.name"
            :value="item.label"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-if="comStatus == '2' || comStatus == '3'"
          v-model="form.classId"
          clearable
          placeholder="选择班级"
          class="select-condition"
          :disabled="disabledClass"
        >
          <el-option
            v-for="item in moduleClassOptions"
            :key="item.label"
            :label="item.name"
            :value="item.label"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-input
          placeholder="姓名"
          clearable
          v-model="form.studentName"
          :onkeyup="(form.studentName = form.studentName.replace(/\s+/g, ''))"
          class="select-condition"
        />
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.morbidityName"
          clearable
          placeholder="疾病名称"
          class="select-condition"
        >
          <el-option
            v-for="item in diseaseOptions"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="searchFun">
          查询
        </el-button>
      </el-form-item>

      <el-form-item>
        <el-button @click="resetFun" icon="el-icon-refresh-left" class="reset">
          重置
        </el-button>
      </el-form-item>
    </el-form>
    <!-- 表格 -->
    <el-table
      :data="tableData"
      :header-cell-style="{
        background: '#F1F4F9',
        color: '#5E6D9B',
        fontWeight: '400',
        'text-align':'center'
      }"
      v-loading="tableLoading"
    >
      <el-table-column
        v-if="comStatus == '3'"
        prop="orgName"
        label="分公司"
        align="center"
      ></el-table-column>
      <el-table-column
        v-if="comStatus == '2' || comStatus == '3'"
        prop="parkName"
        label="园校"
        align="center"
      ></el-table-column>
      <el-table-column prop="className" label="班级" align="center"></el-table-column>
      <el-table-column prop="morbidityDate" label="患病日期" align="center"></el-table-column>
      <el-table-column prop="studentName" label="姓名" align="center"></el-table-column>
      <el-table-column
        prop="morbidityName.label"
        label="疾病名称"
        align="center"
      ></el-table-column>
      <el-table-column prop="measure" label="应对措施" align="center"></el-table-column>
      <el-table-column
        v-if="comStatus == '1' && deptType == 'class'"
        fixed="right"
        label="操作"
        width="120"
        align="center"
      >
        <template slot-scope="{ row }">
          <el-button
            type="text"
            size="medium"
            @click="handleJumpAdded(row)"
            v-if="isEdit"
          >
            编辑
          </el-button>
          <el-popconfirm
            style="margin: 0 0.52083vw"
            @confirm="handleJumpDelete(row)"
            title="删除后将不能恢复，确定删除吗？"
          >
            <el-button
              type="text"
              size="medium"
              class="activeColor"
              slot="reference"
              v-if="isDel"
            >
              删除
            </el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <div class="manger-footer">
      <div class="footer-pagination">
        <el-pagination
          background
          @size-change="handleSizeChange"
          layout="total,sizes,prev,pager,next,jumper"
          :total="pagination.total"
          :page-size="pagination.size"
          @current-change="handleCurrentChangePage"
          :current-page.sync="pagination.currentPage"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getPermissionButton,
  isHasPermission,
} from "@/utils/getUrlPermissionButtons";
import { mapGetters, mapState } from "vuex";

export default {
  data() {
    return {
      form: {
        orgId: "",
        parkId: "",
        classId: "",
        studentName: "",
        morbidityName: "",
      },
      moduleParkOptions: [], //园校下拉
      moduleClassOptions: [], //班级下拉
      diseaseOptions: [], //疾病下拉
      tableData: [],
      pagination: {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        size: 10, // 每页显示多少条
      },
      disabledPark: false,
      disabledClass: true, //是否禁用class选择框
      tableLoading: false, // 表格loading
      deptType: "",
      morbidityDate: "",
      comStatus: "",
      permissionButtonList: [], // 权限按钮list
      isEdit: false,
      isDel: false,
    };
  },

  computed: {
    ...mapState({
      menuList: (state) => state.common.menuList,
      userInfo: (state) => state.userInfo,
      breadcrumbList: (state) => state.common.breadcrumbList,
    }),
  },

  watch: {
    permissionButtonList: {
      handler(val) {
        if (val) {
          console.log(val, "huoshifeipermissionButtonList");
          this.isEdit = val.indexOf("y_morbidity:edit") != -1;
          this.isDel = val.indexOf("y_morbidity:del") != -1;
        }
      },
      deep: true,
    },
    menuList: {
      handler(val) {
        if (val) {
          this.getPermissionButton(this.$route.path, (data) => {
            this.permissionButtonList = data;
          });
        }
      },
      deep: true,
    },
  },

  created() {
    console.log(this.breadcrumbList[0].path, "menuuuuuuuuuuuuuuuuuuu");
    // menulist一直在时这个方法不可缺少
    this.getPermissionButton(this.breadcrumbList[0].path, (data) => {
      this.permissionButtonList = data;
    });
  },

  mounted() {
    this.deptType = this.$route.query.deptType;

    this.comStatus = this.$route.query.comStatus;

    if (this.$route.query.orglookId) {
      this.form.orgId = this.$route.query.orglookId;
    }

    if (this.$route.query.parklookId) {
      this.form.parkId = this.$route.query.parklookId;
      this.disabledPark = true;
      this.disabledClass = false;
      this.getClassInfoList(this.$route.query.parklookId);
    }

    if (this.$route.query.classlookId) {
      this.form.classId = this.$route.query.classlookId;
      this.disabledPark = true;
      this.disabledClass = true;
      this.getClassInfoList(this.$route.query.parklookId);
    }

    this.morbidityDate = this.$route.query.morbidityDate;
    this.tableLoading = true;

    this.getSchoolList();
    this.init();
    this.getDiseaseOptionsList();
  },

  methods: {
    getPermissionButton,
    //返回上一页
    goBack() {
      this.$router.go(-1);
    },

    /** 初始化 */
    init() {
      console.log(this.form.morbidityName);
      let params = {
        morbidityDate: this.morbidityDate,
        orgId: this.form.orgId,
        parkId: this.form.parkId,
        classId: this.form.classId,
        studentName: this.form.studentName,
        morbidityName: this.form.morbidityName,
        current: this.pagination.currentPage,
        size: this.pagination.size,
      };
      this.$api.getMorbidityQueryList(params).then((res) => {
        this.tableLoading = false;
        if (res.data.code == 0) {
          this.tableData = res.data.data.records;
          this.getClassInfoList(res.data.data.records[0].parkId);
          this.pagination.total = res.data.data.total;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    //查询园校
    getSchoolList() {
      let params = {
        parent: this.form.orgId,
        type: "NODE_SCHOOL",
      };
      this.$api.getCompanyDept(params).then((res) => {
        this.tableLoading = false;
        if (res.data.code == "0") {
          this.moduleParkOptions = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    //查询园校change事件
    handlePark(val) {
      if (val) {
        this.disabledClass = false;
        this.getClassInfoList(val);
      }
    },

    //查询班级
    getClassInfoList(valPark) {
      let params = {
        parentLabel: valPark,
      };
      this.$api.getClassInfo(params).then((res) => {
        this.tableLoading = false;
        if (res.data.code == "0") {
          this.moduleClassOptions = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    //查询疾病
    getDiseaseOptionsList() {
      let params = {
        type: "morbidity_name",
      };
      this.$api.getDictionary(params).then((res) => {
        this.tableLoading = false;
        if (res.data.code == "0") {
          this.diseaseOptions = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    //新增患病
    handleAddFill() {
      this.$router.push({
        path: "/morbidtyAdded",
        query: { classlookId: this.$route.query.classlookId }
      });
    },

    //编辑
    handleJumpAdded(row) {
      this.$router.push({
        path: "/morbidtyAdded",
        query: {
          morbidityId: row.morbidityId,
        },
      });
    },

    //删除
    handleJumpDelete(row) {
      let params = {
        morbidityId: row.morbidityId,
      };
      this.$api.getMorbidityRemove(params).then((res) => {
        this.tableLoading = false;
        if (res.data.code == 0) {
          this.$message.success(res.data.msg);
          this.init();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    // 查询
    searchFun() {
      this.pagination.currentPage = 1;
      this.tableLoading = true;
      this.init();
    },

    // 重置
    resetFun() {
	  if (this.deptType == 'org'){
		   this.form.parkId = "";
		   this.form.classId = "";
	  }
	  if (this.deptType == 'park'){
	  	  this.form.classId = "";
	  }
	  this.form.studentName = "";
	  this.form.morbidityName = "",
      this.pagination.currentPage = 1;
      this.tableLoading = true;
      this.init();
    },

    /** 分页 */
    handleCurrentChangePage(val) {
      this.pagination.currentPage = val;
      this.tableLoading = true;
      this.init();
    },
    handleSizeChange(val) {
      this.pagination.size = val;
      this.tableLoading = true;
      this.init();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/select_list.scss";

.contain {
  padding: 34px 30px 30px 30px;
  &.long{
    padding: 26px 30px 30px 30px;
  }
}

.manger-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 30px;
  padding-bottom: 1px;

  .footer-left-title {
    color: #606266;
  }
  .footer-left-num {
    color: red;
  }
}

.allData {
  width: 300px;
  white-space: normal;
}

.handleData {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
